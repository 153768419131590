import { useEffect, useState } from "react";
import { Card, Col, Row, Modal, Alert, Button } from "react-bootstrap";
import {
  ActivityItem,
  IChoiceGroupOption,
  ITheme,
  Icon,
  Label,
  getFocusStyle,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";
import { useSelectedMediaContext } from "../context/MediaContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  PostMediaAnalysisItem,
  MediaAnalysisItem,
  MediaItem,
  AIModelInfoItem,
} from "../models/Media";
import {
  getMediaById,
  postMediaAnalysis,
  updateMediaAnalysis,
} from "../services/Media";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useBoolean } from "@fluentui/react-hooks";
import MediaAnalysisForm from "./MediaAnalysisForm";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import placeholder from "../assets/images/noresults.jpg";
import { getCommunityFeedbackOptions } from "../services/CommunityFeedback";
import fetchData from "../utils/getAllPaginated";
import Select from "react-select";

const MediaAnalysis = () => {
  const { selectedMedia } = useSelectedMediaContext();
  const optionsSave: IChoiceGroupOption[] = [
    {
      key: "true",
      text: "AI Accurate",
      id: "MediaAnalysisFormchoicesSaveAccurate",
    },
    {
      key: "false",
      text: "AI Inaccurate",
      id: "MediaAnalysisFormchoicesSaveInAccurate",
    },
  ];
  const optionsUpdate: IChoiceGroupOption[] = [
    {
      key: "true",
      text: "AI Accurate",
      id: "MediaAnalysisFormchoicesUpdateAccurate",
    },
    {
      key: "false",
      text: "AI Inaccurate",
      id: "MediaAnalysisFormchoicesUpdateInAccurate",
    },
  ];

  const [aiVersions, setAiVersions] = useState<AIModelInfoItem[] | null>(null);
  const [selectedAiVersions, setSelectedAiVersions] =
    useState<AIModelInfoItem | null>(null);
  const [aiModelFeedback, setAiModelFeedback] = useState<
    MediaAnalysisItem[] | null
  >(null);
  const [selectedNote, setSelectedNote] = useState<MediaAnalysisItem | null>(
    null
  );
  const [selectedMediaAnalysisData, setSelectedMediaAnalysisData] =
    useState<MediaAnalysisItem | null>(null);
  const [selectedMediaData, setSelectedMediaData] = useState<MediaItem | null>(
    selectedMedia
  );
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [aiModelInfo, setAiModelInfo] = useState({
    name: "",
    version: "",
    url: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number>(0);
  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);
  const [resetComment, setResetComment] = useState(false);

  const theme: ITheme = getTheme();
  const classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 500,
    },
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        margin: 0,
        boxSizing: "border-box",
        borderBottom: `1px solid lightgrey`,
        display: "flex",
      },
    ],
  });

  useEffect(() => {
    fetchData("/api/v1/ai_model_info").then((ai_models) => {
      let mapped_data = ai_models.map((model: AIModelInfoItem) => {
        return { ...model, value: model.version, label: model.version };
      });
      setAiVersions(mapped_data);
      if (mapped_data.length) {
        setSelectedAiVersions(mapped_data[mapped_data.length - 1]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedMedia) {
      loadMedia();
      setAiModelInfo({
        name: selectedAiVersions?.name || "",
        version: selectedAiVersions?.version || "",
        url: selectedAiVersions?.url || "",
      });
      setResetComment(true); // Reset the comment when media changes
    }
  }, [selectedMedia, selectedAiVersions]);

  useEffect(() => {
    setSelectedNote(
      aiModelFeedback?.length ? aiModelFeedback[selectedNoteIndex] : null
    );
  }, [aiModelFeedback, selectedNoteIndex]);

  useEffect(() => {
    // This effect is to reset displaying error messages when clicking on different medias
    if (selectedMedia) {
      setErrorMessage(null);
      setShowErrorMessage(false);
    }
  }, [selectedMedia]);

  const loadMedia = () => {
    if (selectedMedia?.id) {
      const getMedia$ = getMediaById(selectedMedia?.id);
      const sub = getMedia$.subscribe({
        next: (res) => {
          setSelectedMediaData(res);
          if (res.all_ai_model_feedback?.length) {
            setAiModelFeedback(
              res.all_ai_model_feedback.filter(
                (item: any) => item.ai_model === selectedAiVersions?.url
              )
            );
          } else {
            setAiModelFeedback([]);
          }
        },
      });
      return () => sub.unsubscribe();
    }
  };
  useEffect(() => loadMedia(), []);

  const saveMediaAnalysis = (
    hasAICorrectlyDetected: boolean,
    comment: string | undefined,
    id: number | null
  ): (() => void) => {
    if (selectedMediaData?.url && !id) {
      const toastId = toast.loading("Please wait...");
      let payload: PostMediaAnalysisItem = {
        comment: !hasAICorrectlyDetected && comment ? comment : "",
        media: selectedMediaData.id,
        has_ai_correctly_detected: hasAICorrectlyDetected,
        ai_model: aiModelInfo.url,
      };
      const postMediaAnalysisItem$ = postMediaAnalysis(payload);
      const sub = postMediaAnalysisItem$.subscribe({
        next: () => {
          toast.update(toastId, {
            render: "Media notes saved successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });

          loadMedia();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status === 400) {
            const errorResponse = e.response;
            if (errorResponse?.non_field_errors?.length > 0) {
              setErrorMessage(errorResponse.non_field_errors[0]);
              setShowErrorMessage(true);
            }
          }
          console.error("Error", e);
        },
      });
      setResetComment(true); // Reset the comment field after saving
      return () => sub.unsubscribe();
    }

    if (selectedMediaData?.url && id) {
      const toastId = toast.loading("Please wait...");
      let payload: PostMediaAnalysisItem = {
        comment: !hasAICorrectlyDetected && comment ? comment : "",
        media: selectedMediaData.id,
        has_ai_correctly_detected: hasAICorrectlyDetected,
        id: id,
        ai_model: aiModelInfo.url,
      };
      const updateMediaAnalysisItem$ = updateMediaAnalysis(payload);
      const sub = updateMediaAnalysisItem$.subscribe({
        next: () => {
          hideModal();
          toast.update(toastId, {
            render: "Media notes updated successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
          loadMedia();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status === 400) {
            const errorResponse = e.response;
            if (errorResponse?.non_field_errors?.length > 0) {
              setErrorMessage(errorResponse.non_field_errors[0]);
              setShowErrorMessage(true);
            }
          }
          console.error("Error", e);
        },
      });
      return () => sub.unsubscribe();
    }
    return () => {};
  };

  const getUserNote = (analysis: MediaAnalysisItem) => {
    return (
      <>
        <div>
          AI Accurate: {analysis.has_ai_correctly_detected ? "True" : "False"}
        </div>
        {analysis.comment && <div>Note: {analysis.comment}</div>}
      </>
    );
  };

  const handlePreviousNote = () => {
    setSelectedNoteIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextNote = () => {
    const maxIndex = aiModelFeedback?.length ? aiModelFeedback?.length - 1 : 0;
    setSelectedNoteIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  useEffect(() => {
    if (selectedNote && selectedNote.id) {
      checkEditPermission(selectedNote.id);
    }
  }, [selectedNote]);

  const checkEditPermission = (id: number) => {
    const getCommunityFeedbackOptions$ = getCommunityFeedbackOptions(id);
    const sub = getCommunityFeedbackOptions$.subscribe({
      next: (res: any) => {
        setHasEditPermission(!!res.actions?.PUT);
      },
    });
    return () => sub.unsubscribe();
  };

  return (
    <>
      {selectedMedia &&
        selectedMedia.all_ai_model_identified_species_counts?.length > 0 && (
          <>
            <Card border="light" className="shadow-sm">
              <Card.Body>
                <Row className="d-block d-flex align-items-center">
                  <Col
                    xs={12}
                    xl={8}
                    className="align-items-center justify-content-xl-center"
                  >
                    <div className="d-sm-block">
                      <h6 className="mb-2">
                        <small>{aiModelInfo.name}</small>
                      </h6>
                      <h6 className="mb-2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={
                            aiVersions?.length
                              ? aiVersions[aiVersions.length - 1]
                              : undefined
                          }
                          isDisabled={!aiVersions?.length}
                          isLoading={!aiVersions?.length}
                          onChange={setSelectedAiVersions}
                          name="ai_model_version"
                          options={aiVersions ? (aiVersions as any) : undefined}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              minHeight: "30px", // Adjust the height for smaller size
                              fontSize: "12px", // Smaller font size
                              padding: "0px 4px", // Adjust padding
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0px 6px", // Adjust padding inside the container
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: "0px", // Adjust input margin
                              padding: "0px", // Adjust input padding
                            }),
                            indicatorsContainer: (provided) => ({
                              ...provided,
                              height: "30px", // Match the control height
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0px", // Adjust dropdown indicator padding
                            }),
                            option: (provided) => ({
                              ...provided,
                              fontSize: "12px", // Smaller font for individual options
                              padding: "6px 8px", // Adjust padding for a compact look
                            }),
                          }}
                        />
                      </h6>
                    </div>
                    {selectedMediaData?.all_ai_model_identified_species_counts?.map(
                      (object, index) => {
                        if (object.ai_model === selectedAiVersions?.url) {
                          return (
                            <div key={index + object.identified_object_name}>
                              <small>{object.identified_object_name}</small>
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* <span className="d-block font-weight-bold mt-2">
              <small>Identified Spacies:</small>
            </span> */}
            {aiModelFeedback?.length === 0 ? (
              <Card border="light" className="shadow-sm p-0 mt-2">
                <Card.Body>
                  {" "}
                  <Row>
                    <Label>No notes added.</Label>
                  </Row>
                </Card.Body>
              </Card>
            ) : (
              <Card border="light" className="shadow-sm p-0 mt-2">
                <Card.Body style={{ paddingTop: "0" }}>
                  <Row>
                    {/* {aiModelFeedback?.length !== 0 && (
                    <Label style={{ fontSize: "16px" }}>
                      Media Analysis Notes
                    </Label>
                  )} */}

                    {selectedNote && (
                      <div
                        className={classNames.itemCell}
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <ActivityItem
                            comments={getUserNote(selectedNote)}
                            activityDescription={`${selectedNote.created_by} added`}
                            timeStamp={moment(
                              selectedNote.created_at
                            ).fromNow()}
                            activityIcon={
                              <Icon
                                iconName={"Message"}
                                style={{ marginTop: "2px", marginRight: "2px" }}
                              />
                            }
                            styles={{ root: { fontSize: "13px" } }}
                          />
                        </div>
                        {hasEditPermission && (
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                              setSelectedMediaAnalysisData(selectedNote);
                              showModal();
                            }}
                            style={{
                              position: "absolute",
                              top: "15px",
                              right: "20px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Row>
                  {selectedNote && (
                    <Row className="mt-2">
                      <Col xs={6} className="d-flex justify-content-start">
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={handlePreviousNote}
                          disabled={selectedNoteIndex === 0}
                        >
                          Previous
                        </Button>
                      </Col>
                      <Col xs={6} className="d-flex justify-content-end">
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={handleNextNote}
                          disabled={
                            selectedNoteIndex ===
                            (aiModelFeedback?.length || 0) - 1
                          }
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            )}
            {/* <span className="d-block font-weight-bold mt-2">
              <small>Add Your Analysis:</small>
            </span> */}
            <Card border="light" className="shadow-sm mt-2">
              <Card.Body>
                {/* <Label style={{ fontSize: "16px" }}>Add Your Analysis</Label> */}
                {showErrorMessage && (
                  <Alert
                    variant="danger"
                    onClose={() => setShowErrorMessage(false)}
                    dismissible
                  >
                    {errorMessage}
                  </Alert>
                )}
                <MediaAnalysisForm
                  options={optionsSave}
                  onSave={saveMediaAnalysis}
                  saveButtonText="Save"
                  resetComment={resetComment}
                  setResetComment={() => setResetComment(false)}
                />
              </Card.Body>
            </Card>
          </>
        )}

      {selectedMedia?.all_ai_model_identified_species_counts?.length === 0 && (
        <div>
          <Card border="light" className="shadow-sm">
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ maxWidth: "100%", margin: "auto", minHeight: "520px" }}
              >
                <img
                  src={placeholder}
                  alt="Placeholder"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h6 style={{ marginBottom: "1rem", textAlign: "center" }}>
                No AI results available for this media.
              </h6>
            </Card.Body>
          </Card>
        </div>
      )}
      <Modal show={isModalOpen} backdrop="static" onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Edit Media Analysis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaAnalysisForm
            options={optionsUpdate}
            onSave={saveMediaAnalysis}
            onCancel={hideModal}
            cancelButtonText="Cancel"
            data={selectedMediaAnalysisData}
            saveButtonText="Update"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MediaAnalysis;
