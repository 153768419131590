import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "../utils/input_icon";

interface DateYearPickerProps {
  yearNames: any; // Type could be either Value from "react-multi-date-picker" or string[]
  setYearNames: React.Dispatch<React.SetStateAction<any>>;
}

const DateYearPicker: React.FC<DateYearPickerProps> = ({
  yearNames,
  setYearNames,
}) => {
  useEffect(() => {
    // Set initial value of year input box
    setYearNames([new Date().getFullYear()]);
  }, []);

  const handleYearChange = (dates: any) => {
    const sortedYears = dates
      .map((date: any) =>
        typeof date === "object" && date instanceof Date
          ? date.getFullYear()
          : new Date(date).getFullYear()
      )
      .sort((a: number, b: number) => a - b); // Sort years in ascending order

    setYearNames(sortedYears);
  };
  const formattedYearNames = Array.isArray(yearNames)
    ? yearNames
        .map((date) =>
          typeof date === "object" && date instanceof Date
            ? date.getFullYear()
            : new Date(date).getFullYear()
        )
        .join(", ")
    : "";

  return (
    <>
      <span className="d-block fw-bold mb-1">
        <small>Year Range:</small>
      </span>
      <Form className="w-100">
        <Form.Group
          className="mb-3 w-100"
          controlId="exampleForm.ControlInput1"
        >
          <DatePicker
            value={yearNames}
            onChange={handleYearChange}
            onlyYearPicker
            multiple
            plugins={[<DatePanel />]}
            style={{
              width: "60%",
              height: "26px",
            }}
            containerStyle={{
              width: "100%",
            }}
            render={
              <InputIcon
                className="form-control mb-3 bg-white w-90"
                value={formattedYearNames}
                openCalendar={() => {}}
                separator=", "
                locale="en"
                iconStyle={{ cursor: "pointer" }}
              />
            }
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default DateYearPicker;
